import webRequest from './WebRequest'

function getData(type, url, data) {
  return new Promise((resolve) => {
    webRequest({
      method: type,
      url: url,
      data: data
    }).then((res) => {
      if (res.data.code === 200) {
        resolve(res)
      } else {
        this.$message({
          message: res.data.msg,
          type: 'warning'
        });
      }
    }).catch((err) => { })
  })
}

export default getData
