<template>
  <div class="footer">
    <div class="footer-auto">
      <div class="footer-auto-top">
        <el-row style="width: 100%;">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="footer-auto-top-item">
              <h3>Contact Us</h3>
              <p>USA:1-213-355-7171</p>
              <p>Support@vdiagtool.com</p>
              <p>Monday to Friday:</p>
              <p>8:00AM - 5:00PM PST</p>
            </div>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="footer-auto-top-item">
              <h3>Buy on the VDIAGTOOL Store</h3>
              <p>Hassle-Free Warranty</p>
              <p>60-Day Money-Back Guarantee</p>
              <p>Fast & Free Shipping</p>
              <p>Lifetime Customer Support</p>
            </div>
          </el-col>
        <!-- <div class="footer-auto-top-item">
          <h3></h3>
        </div> -->
        <!-- <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="footer-auto-top-item">
            <h3>United Stales / English</h3>
            <el-dropdown>
              <h3>
                <span class="el-dropdown-link">
                  United Stales / English <i class="el-icon-arrow-down"></i>
                </span>
              </h3>
              <el-dropdown-menu class="footerDropdown" slot="dropdown">
                <el-dropdown-item>English</el-dropdown-item>
                <el-dropdown-item>中文</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-col> -->
      </el-row>
      </div>
      <div v-if="isPC" class="footer-auto-bottom">
        <div 
          class="footer-auto-bottom-item"
          v-for="(item, index) in menuAll" :key="index"
        >
          <h3 @click="toPage(item)">{{ item.category_name }}</h3>
          <p v-for="(item2, index2) in item.children" :key="index2" @click="toPage(item2)">{{ item2.category_name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
export default {
  name: 'FooterBar',
  props: {
    isPC: Boolean
  },
  methods: {
    toPage(item) {
      this.isShowSearchBox = false
      //收起mMenu和subMMenu
      this.isShowMMenu = false
      this.isShowMSubMenu = this.closeSubMenu
      this.$router.push({
        name: item.category_label,
        query: {
          id: item.category_id
        }
      });
    },
  },
  computed: {
    // ...mapState(['menuAll']),
    menuAll() {
      return this.$store.state.menuAll
    }
  }
}
</script>

<style lang="scss" scoped>
.footer{
  width: 100%;
  background: #231f20;
  margin-top: 50px;
  overflow: hidden;
}
.footer h3{
  color: #d7d4d4;
  font-weight: 500;
  font-size: 1.5rem;
}
.footer p{
  color: #a7a2a2;
}
.footer-auto{
  width: 60%;
  margin: auto;
  padding: 1rem;
}
.footer-auto-top{
  display: flex;
  justify-content: space-between;
}
.footer-auto-bottom{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #5a5a5a;
}
.footer-auto-top-item{
  // width: 25%;
  text-align: left;
}
.footer-auto-bottom-item {
  // width: 25%;
  text-align: left;
  cursor: pointer;
}

.el-icon-arrow-down:before {
    content: "\e6df";
}
</style>

