<template>
  <div class="headerBox">
    <div class="pcBox" v-if="isPC">
      <div class="header showSearch" :style="{height: isShowSearchBox? '120px': '61px'}">
        <div class="header-auto">
          <div class="header-logo" @click="$router.push('/home')"><img src="../assets/logo.png"></div>
          <div class="header-menu">
            <ul>
              <li v-if="menuAll.length > 0" :class="$route.path == '/home'?'p-active':''" @click="$router.push('/home')">Home</li>
              <li 
                v-for="(item,index) in menuAll" 
                :key="index" 
                :class="$route.path == item.category_path+''?'p-active':''" 
                @click="toPage(item)" 
                @mouseenter="mouseenterChange(item)"
                v-if="(item.category_name != 'Personal Center') && (item.category_name != 'Service Center')"
              >{{item.category_name}}</li>
            </ul>
          </div>
          <div class="header-user">
            <ul>
              <li style="margin-right: 15px;" @click="showSearchBox()"><i style="color: #8c8c8c;" :class="!isShowSearchBox? 'el-icon-search': 'el-icon-error'"></i></li>
              <li style="margin-right: 15px;" v-if="isServiceCenter"><i style="color: #8c8c8c;font-size: 1.5rem;" @click="toPage(serviceCenterItem)" class="el-icon-service"></i></li>
              <!-- <li style="margin-right: 15px;" @click="$router.push('/shopping')"><img src="../assets/shopping.png"></li> -->
              <li class="userModule">
                <!-- <el-popover
                  placement="bottom"
                  width="300"
                  trigger="hover">
                  <div class="userModuleBox" v-if="isLogin == true">
                    <div class="top">
                      <div class="left">
                        <img v-if="(isLogin == true) && (HeadPicUrl != '')" :src="HeadPicUrl" @click="toPage(personalCenterChildrenData[0])">
                        <img v-if="(isLogin == false) || (HeadPicUrl == '')" src="../assets/user.png">
                      </div>
                      <div class="right">
                        <div class="t">
                          <span class="name" @click="toPage(personalCenterChildrenData[0])">{{ localUserInfo.uName }}</span>
                          <span class="level" @click="$router.push('/member')"><img src="../assets/member-icon.png" alt=""></span>
                        </div>
                        <div class="b">
                          <span class="integral"><i class="el-icon-star-on"></i>123会员积分 | </span>
                          <span class="setting" @click="toPage(personalCenterChildrenData[0])">设置 | </span>
                          <span class="exit" @click="exitBtn">退出</span>
                        </div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="levelBox" @click="$router.push('/member')"><span>解锁更多会员</span><i class="el-icon-arrow-right"></i></div>
                    </div>
                  </div>

                  <div class="userModuleBox userNoLogin" v-if="isLogin == false">
                    <div class="top">
                      <div class="left">
                        <img src="../assets/user.png">
                      </div>
                      <div class="right">
                        <div class="t">
                          <span class="toLoginBtn" @click="$router.push('/login')">立即登录<i class="el-icon-arrow-right"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span slot="reference" class="picBtn">
                    <img v-if="(isLogin == true) && (HeadPicUrl != '')" :src="HeadPicUrl">
                    <img v-if="(isLogin == false) || (HeadPicUrl == '')" src="../assets/user.png">
                  </span>
                </el-popover> -->


                <el-dropdown class="header-user-dropdown" @click.native="isLogin? '' : $router.push('/login')">
                  <span class="el-dropdown-link HeadPic">
                    <img v-if="(isLogin == true) && (HeadPicUrl != '')" :src="HeadPicUrl">
                    <img v-if="(isLogin == false) || (HeadPicUrl == '')" src="../assets/user.png">
                  </span>
                  <el-dropdown-menu slot="dropdown" :split-button=true style="border: none;">

                    
                    <el-dropdown-item v-if="(isLogin == true) && personalCenterChildrenData[0]" @click.native="toPage(personalCenterChildrenData[0])">{{ personalCenterChildrenData[0].category_name }}</el-dropdown-item>
                    <el-dropdown-item v-if="isLogin == true" @click.native="exitBtn">Sign Out</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </div>
        <div class="searchBox header-auto clearfloat">
          <el-select class="searchSelect" v-model="searchSelectVal" placeholder="Select Type">
            <el-option
              v-for="item in searchOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <input type="text" placeholder="-Please enter a keyword-" v-model="searchContent">
          <span @click="toSearchPage"><i class="el-icon-search"></i></span>
        </div>
      </div>
      <div class="sub-menu" @mouseleave="mouseleaveChange" v-if="isSubMenu">
        <div class="sub-menu-auto">
          <div class="sub-menu-name">
            <div class="sub-menu-item" v-for="(item,index) in children" :key="index" @click="toPage(item)" @mouseenter="showSubMenuContent(item)">
              <div :class="$route.path == item.category_path+''?'s-active':''">{{item.category_name}}</div>
              <div><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
          <div class="sub-menu-pic">
            <div v-if="isProduct" class="productBox">
              <div class="list-item borderBox" v-for="(item, index) in productShowData.slice(0,4)" :key="index" @click="toContent(item.goods_id)">
                <div class="labelBox" v-if="item.goods_tag_id != 1">
                  <span v-if="item.goods_tag_id == 2"><img src="../assets/product/icon-new.png" alt="new"></span>
                  <span v-if="item.goods_tag_id == 3"><img src="../assets/product/icon-hot.png" alt="hot"></span>
                  <!-- <span v-if="item.goods_tag_id == 4"><img src="../assets/product/icon-rcmd.png" alt="rcmd"></span> -->
                </div>
                <div class="item-pic"><img :src="$imgUrl + item.goods_picture[0]"></div>
                <div class="title slh-1">{{ item.goods_title }}</div>
                <!-- <div class="item-param">${{ item.goods_selling_price }}</div>
                <div class="purchase">purchase</div> -->
              </div>
            </div>
            <div v-else class="aboutBox" :style="'background-image:url('+$imgUrl + menuShowData.category_picture+')'">
              <div class="showBox" @click="toPage(menuShowData.itemMsg)">
                <div class="cont">
                  <div class="category_name">{{ menuShowData.category_name }}</div>
                  <div class="category_introduce">{{ menuShowData.category_introduce }}</div>
                </div>
                <span>Click to view</span>
              </div>
              <!-- {{ $imgUrl + menuShowData.category_picture }} -->
              <!-- <img :src="$imgUrl + menuShowData.category_picture" alt=""> -->
            </div>
            <!-- <div v-else-if="isAbout" class="aboutBox">
              <div class="showBox" @click="toPage(menuShowData.itemMsg)">
                <div class="cont">
                  <div class="category_name">{{ menuShowData.category_name }}</div>
                  <div class="category_introduce">{{ menuShowData.category_introduce }}</div>
                </div>
                <span>Click to view</span>
              </div>
            </div> -->
            <!-- <div v-else-if="isSupport" class="supportBox aboutBox">
              <div class="showBox" @click="toPage(menuShowData.itemMsg)">
                <div class="cont">
                  <div class="category_name">{{ menuShowData.category_name }}</div>
                  <div class="category_introduce">{{ menuShowData.category_introduce }}</div>
                </div>
                <span>Click to view</span>
              </div>
            </div> -->
            <!-- <img v-else src="../assets/banner-menu.png"> -->
          </div>
        </div>
      </div>
    </div>
    
    <div class="mBox" v-else>
      <div class="mHeader">
        <div class="mTop">
          <img @click="$router.push('/home')" src="../assets/logo.png" alt="">
          <button @click="showMenu()"><i class="el-icon-s-fold"></i></button>
        </div>
        <transition name="mMenuDH">
          <div class="mMenu" v-show="isShowMMenu">
            <div class="mMenuBox" v-if="menuAll.length > 0" :class="$route.path == '/home'?'p-active':''" @click="$router.push('/home');showMenu()">
              <span class="mTitle">Home</span></div>
            <div class="mMenuBox"
              v-for="(item,index) in menuAll" 
              :key="index" 
              :class="$route.path == item.category_path+''?'p-active':''" 
              @click="showSubMMenu(index)"
            >
              <span class="mTitle">{{item.category_name}}</span>
              <transition name="subMenuDH">
                <ul class="subMenu"
                  v-show="isShowMSubMenu == index">
                  <!-- <template v-if="item.category_name == 'Personal Center'">
                    <div class="userModuleBox" v-if="isLogin == true">
                      <div class="top">
                        <div class="left">
                          <img v-if="(isLogin == true) && (HeadPicUrl != '')" :src="HeadPicUrl" @click="toPage(personalCenterChildrenData[0])">
                          <img v-if="(isLogin == false) || (HeadPicUrl == '')" src="../assets/user.png">
                        </div>
                        <div class="right">
                          <div class="t">
                            <span class="name" @click="toPage(personalCenterChildrenData[0])">{{ localUserInfo.uName }}</span>
                            <span class="level" @click="$router.push('/member')"><img src="../assets/member-icon.png" alt=""></span>
                          </div>
                          <div class="b">
                            <span class="integral"><i class="el-icon-star-on"></i>123会员积分 | </span>
                            <span class="setting" @click="toPage(personalCenterChildrenData[0])">设置 | </span>
                            <span class="exit" @click="exitBtn">退出</span>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="levelBox" @click="$router.push('/member')"><span>解锁更多会员</span><i class="el-icon-arrow-right"></i></div>
                      </div>
                    </div>
                    <div class="userModuleBox userNoLogin" v-if="isLogin == false">
                    <div class="top">
                      <div class="left">
                        <img src="../assets/user.png">
                      </div>
                      <div class="right">
                        <div class="t">
                          <span class="toLoginBtn" @click="$router.push('/login')">立即登录<i class="el-icon-arrow-right"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </template> -->

                  <template>
                    <li @click="toPage(item)">{{item.category_name}}</li>
                    <li 
                      v-for="(subItem,subIndex) in item.children" 
                      :key="subIndex" 
                      @click="toPage(subItem)"
                    >{{subItem.category_name}}</li>
                  </template>
                </ul>
              </transition>
              
            </div>
          </div>
        </transition>
        
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisibleMailBox"
      width="50%"
      :before-close="handleCloseMailBox"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      >
      <h2>Be the first to know</h2>
      <p>Get the latest product releases and best deals delivered to your inbox.</p>
      <el-form :model="ruleFormMailBox" :rules="rulesMailBox" ref="refMailBox" class="demo-ruleForm">
        <el-form-item prop="mailbox">
          <el-input v-model="ruleFormMailBox.mailbox" clearable placeholder="Email address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseMailBox">No, thanks</el-button>
        <el-button type="primary" @click="submitMail">Subscribe</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import {mapState} from "vuex"
export default {
  name: 'HeaderBar',
  data() {
    return {
      isSubMenu: false,
      isShowSearchBox: false,
      isServiceCenter: false,//是否显示服务中心
      serviceCenterItem: '',//服务中心 item
      children: [],
      isShowMMenu: false,
      isShowMSubMenu: this.closeSubMenu,
      closeSubMenu: 999,
      searchContent: '',
      searchOptions: [
        {
          value: 'article',
          label: 'Article'
        },
        {
          value: 'goods',
          label: 'Products'
        }
      ],
      searchSelectVal: '',
      activeMenuId: '',
      activeSubMenuId: '',
      currentComponent: '',
      isProduct: false,
      productAllList: [],
      productShowData: [],
      isAbout: false,
      menuShowData: {
        category_picture: '',
        category_name: '',
        category_introduce: ''
      },
      isSupport: false,
      //用户头像url
      localUserInfo: {},
      HeadPicUrl: '',
      personalCenterChildrenData: [],//个人中心Children Data
      
      //邮箱弹窗
      dialogVisibleMailBox: false,
      ruleFormMailBox: {},
      rulesMailBox: {
        mailbox: [
          {required: true, message:"Please enter valid email address!", trigger: "blur"},
          {pattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/, message: 'Please enter valid email address!', trigger: "blur"}
        ]
      }
      //邮箱弹窗
    }
  },
  props: {
    isPC: Boolean
  },
  created() {
    //登录状态处理
    if(localStorage.getItem('token')){
      this.$store.commit('setIsLogin',true)
      if(localStorage.getItem('userInfo')) {
        let userInfoStr = localStorage.getItem('userInfo')
        let userInfo = JSON.parse(userInfoStr)
        if(userInfo.uPicture) {
          this.localUserInfo = userInfo
          this.HeadPicUrl = userInfo.uPicture
        }
      }
    }else{
      this.$store.commit('setIsLogin',false)
    }

    // 判断是否显示获取邮箱弹窗
    if(!localStorage.getItem('isEAccount')) {
      if(!sessionStorage.getItem('hideMailBox')) {
        this.dialogVisibleMailBox = true
      }
    }
  },
  mounted() {
    let that = this
    document.onkeydown = function (e) {
      e = window.event || e
      if(e.code === 'Enter') {
        that.toSearchPage()
      }
    }
  },  
  computed: {
    ...mapState(['isLogin','menuAll','userInfo']),
  },
  methods: {
    cleanIs() {
      this.isProduct = false
      this.isAbout = false
      this.isSupport = false
    },
    //退出登录
    exitBtn() {
      if(localStorage.getItem('token')) {
        this.isShowMMenu = false
        this.isShowMSubMenu = this.closeSubMenu
        var that = this;
        that.$confirm('Are you sure you want to log out?', 'Tios', {
          confirmButtonText: 'YES',
          cancelButtonText: 'NO',
          type: 'warning'
        }).then((confirm) => {
          if(confirm === 'confirm'){
            //发起Ajax请求
            this.$webRequest({
              method: 'get',
              url: '/api/user/check_logout'
            }).then((res) => {
              if(res.data.code === 200){
                //清除登录状态
                that.$logout()
              }
            }).catch((err) => {})
          }
        }).catch((err) => {})
      }
      // localStorage.removeItem('token')
      // this.$router.push('/login');
      // this.$router.go(0)
    },
    //移入事件
    mouseenterChange(item) {
      //判断是否有子类
      if(item.children != undefined){
        this.children = item.children;
        this.isSubMenu = true;
      }else{
        this.isSubMenu = false;
        this.children = [];
        return
      }
      this.cleanIs()
      this.activeMenuId = item.category_id || ''
      this.currentComponent = item.category_component
      if(this.currentComponent === "/ProductPage") {
        this.isProduct = true
        if(this.productAllList.length > 0) {this.productShowData = this.productAllList[0].goods_all || []}
        else if(this.productAllList.length <= 0) {
          this.$getData('get', '/api/api/category_goods', {category_id: this.activeMenuId}).then( res => {
            this.productAllList = res.data.data
            this.productShowData = this.productAllList[0].goods_all || []
          })
        }
      }
      else {
        if(item.children.length > 0) {
          this.menuShowData.category_picture = item.children[0].category_picture || ''
          this.menuShowData.category_name = item.children[0].category_name || ''
          this.menuShowData.category_introduce = item.children[0].category_introduce || ''
          this.menuShowData.itemMsg = {
            category_label: item.children[0].category_label,
            category_id: item.children[0].category_id
          }
        }
        
      }
      // else if(this.currentComponent === "/AboutPage") {
      //   this.isAbout = true
      // }
      // else if (this.currentComponent === "/SupportPage") {
      //   this.isSupport = true
      // }
      // else { 
      //   this.cleanIs()
      // }
      // if((this.currentComponent === "/AboutPage") || (this.currentComponent === "/SupportPage")) {
      //   if(this.menuShowData.category_name === '') {
      //     this.menuShowData.category_name = item.children[0].category_name || ''
      //     this.menuShowData.category_introduce = item.children[0].category_introduce || ''
      //     this.menuShowData.itemMsg = {
      //       category_label: item.children[0].category_label,
      //       category_id: item.children[0].category_id
      //     }
      //   }
      // }
      
      
    },
    //移出事件
    mouseleaveChange() {
      this.isSubMenu = false;
      this.children = [];
      this.cleanIs()
    },
    //一级菜单跳转详情页
    parentDetails(item1) {
      this.$router.push({
        name: item1.category_label,
        query: {
          id: item1.category_id
        }
      });
    },
    // 是否显示搜索条
    showSearchBox() {
      this.searchContent = ''
      this.searchSelectVal = ''
      this.isSubMenu = false;
      this.children = [];
      this.isShowSearchBox = !this.isShowSearchBox
    },
    toPage(item) {
      this.isShowSearchBox = false
      this.mouseleaveChange()
      //收起mMenu和subMMenu
      this.isShowMMenu = false
      // this.isShowMSubMenu = this.closeSubMenu
      this.$router.push({
        name: item.category_label,
        query: {
          id: item.category_id
        }
      });
    },
    showSubMenuContent(item) {
      this.activeSubMenuId = item.category_id
      if(this.currentComponent === "/ProductPage") {
        this.productAllList.forEach((item) => {
          if(item.category_id === this.activeSubMenuId) {
            this.productShowData = item.goods_all || []
            return false
          }
        });
      }
      else {
        this.menuShowData.category_picture = item.category_picture || ''
        this.menuShowData.category_name = item.category_name || ''
        this.menuShowData.category_introduce = item.category_introduce || ''
        this.menuShowData.itemMsg = {
          category_label: item.category_label,
          category_id: item.category_id
        }
      }
      // else if ((this.currentComponent === "/AboutPage") || (this.currentComponent === "/SupportPage")) {
      //   this.menuShowData.category_name = item.category_name || ''
      //   this.menuShowData.category_introduce = item.category_introduce || ''
      //   this.menuShowData.itemMsg = {
      //     category_label: item.category_label,
      //     category_id: item.category_id
      //   }
      // }
      
    },
    toContent(goods_id) {
      this.$router.push({
        name: 'ProductContent',
        query: {
          gid: goods_id
        }
      })
    },
    toSearchPage() {
      if(!this.isShowSearchBox) {
        return
      }
      if(this.searchContent == ''){
        this.$message({
          message: 'Please enter keywords',
          type: 'warning'
        });
        return false;
      }
      if(this.searchSelectVal == '') {
        this.$message({
          message: 'Please select type',
          type: 'warning'
        });
        return false;
      }
      this.$router.push({
        name: 'search',
        query: {
          type: this.searchSelectVal,
          keywords: this.searchContent
        }
      });

    },
    //手机端--menu下拉
    showMenu() {
      this.isShowMMenu = !this.isShowMMenu
      this.isShowMSubMenu = this.closeSubMenu
    },
    //手机端--subMenu下拉
    showSubMMenu(index) {
      if(this.isShowMSubMenu == index) {
        this.isShowMSubMenu = this.closeSubMenu
        return false
      }
      this.isShowMSubMenu = index
    },

    submitMail() {
      this.$refs.refMailBox.validate((valid) => {
        if (!valid) {
          return;
        }
        const eAccount = this.ruleFormMailBox.mailbox
        //发起请求
        this.$webRequest({
          method: 'post',
          url: '/api/home/email_add',
          data: {eAccount: eAccount}
        }).then((res)=>{
          if(res.data.code === 200){
            localStorage.setItem('isEAccount', eAccount)
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
          }else{
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
          this.handleCloseMailBox()
        }).catch((err) => {})
      })
    },
    handleCloseMailBox() {
      this.ruleFormMailBox = {}
      this.dialogVisibleMailBox = false
      sessionStorage.setItem('hideMailBox', 'yes')
    }
  },
  watch: {
    userInfo(newValue) {
      this.HeadPicUrl = newValue.uPicture
      this.localUserInfo = newValue
    },
    menuAll(newValue) {
      newValue.forEach(item => {
        if(item.category_name == "Personal Center") {
          this.personalCenterChildrenData = item.children
        }
        if(item.category_name == "Service Center") {
          this.isServiceCenter = true
          this.serviceCenterItem = item
        }
      });
    },
    $route(newValue) {
      this.isShowMMenu = false
      this.isShowMSubMenu = this.closeSubMenu
    }
  }
}
</script>
<style lang="scss" scoped>
.header{
  width: 100%;
  height: 61px;
  background: #ffffff;
  border-bottom: 1px #d8d7d7 solid;
  overflow: hidden;
  transition: 0.5s;
  box-sizing: border-box;
  position: fixed;
  top: 0;left: 0;
  z-index: 999;
  box-shadow: 0 2px 5px #ccc;
  .header-auto{
    width: 60%;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-logo{
      width: 18%;
    }
    .header-logo img{
      width: 80%;
      cursor: pointer;
    }
    .header-menu{
      width: 60%;
      ul{
        width: 100%;
        display: flex;
        justify-content: space-between;
        li{
          line-height: 58px;
          border-bottom: 4px #ffffff solid;
        }
        li:hover{
          border-bottom: 4px #179fcf solid;
          cursor: pointer;
        }
        .p-active{
          border-bottom: 4px #179fcf solid;
        }
      }
    }
    .header-user{
      width: 10%;
      ul{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        li{
          width: 20%;
          cursor: pointer;
          display: flex;
          align-items: center;
          .HeadPic {
            display: inline-block;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            // border: 1px solid #666;
            img {
              border-radius: 50%;
            }
          }
        }
        li img{
          width: 22px;
          height: 22px;
        }
      }

      .userModule {
        .picBtn {
          display: inline-block;
          width: 25px;
          height: 25px;
          border: 1px solid #ccc;
          border-radius: 25px;
          img {
            width: 100%;
            height: 25px;
            border-radius: 25px;
            border: none;
          }
        }
      }
      
    }
  }
}
.el-icon-search,.el-icon-error {
  font-size: 1.5rem;
}
.showSearch {
  height: 120px;
  .searchBox {
    display: block;
    height: 40px;
    border-radius: 40px;
    margin-top: 10px;
    border: 1px solid #aaa;
    .searchSelect {
      width: 120px;
      border-radius: 40px;
      border: none;
      float: left;
    }
    input {
      width: 64%;
      height: 36px;
      border: none;
      outline: none;
      margin-left: 1%;
      border-radius: 40px;
      float: left;
    }
    span {
      float: right;
      width: 80px;
      height: 36px;
      background-color: #eee;
      border-radius: 36px;
      margin-right: 4px;
      margin-top: 2px;
      line-height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        height: 20px;
      }
    }

  }
} 
.sub-menu{
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 61px;
  z-index: 999;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0 2px 5px #ccc;
  transition: 0.3s;
  .sub-menu-auto{
    width: 60%;
    margin: auto;
    padding: 2%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s;
    .sub-menu-name{
      width: 20%;
      display: flex;
      flex-direction: column;
      text-align: left;
      .sub-menu-item{
        display: flex;
        justify-content: space-between;
        padding: 5% 0;
        // line-height: 40px;
      }
      .s-active{
        border-bottom: 2px #179fcf solid;
      }
    }
    .sub-menu-pic{
      display: flex;
      width: 78%;
      img{
        width: 100%;
        height: max-content;
      }
    }
  }
}

// 用户模块
.userModuleBox {
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    .left {
      margin-right: 15px;
      img {width: 40px;height: 40px;border: 1px solid #ccc;border-radius: 40px;cursor: pointer;}
    }
    .right {
      color: #777;
      .t {
          display: flex;
          align-items: center;
          flex-direction: row;
        margin-bottom: 3px;
        .name {color: #a67128;margin-right: 10px;cursor: pointer;}
        .level {
          cursor: pointer;
          img {height: 16px;}
        }
      }
      .b {
        display: flex;
        flex-direction: row;
        .integral {
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            padding: 2px;
            background-color: #fcb735;
            border-radius: 50%;
            color: #fff;
            font-size: 10px;
            margin-right: 5px;
          }
        }
        .setting {cursor: pointer;margin-left: 5px;}
        .exit {cursor: pointer;margin-left: 5px;}
        .integral:hover, .setting:hover, .exit:hover {
          color: #179fcf;
        }
      }
    }
  }
  .bottom {
    .levelBox {
      cursor: pointer;
      padding: 30px 20px;
      background-color: #ffddad;
      border-radius: 5px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #663c00;
    }
  }
}
.userModuleBox.userNoLogin {
  .toLoginBtn {cursor: pointer;}
}
// 用户模块

.productBox {
  width: 100%;
  display: flex;
  .list-item {
    width: 23%;
    margin: 1%;
    padding: 10px;
    position: relative;
    .labelBox {
      position: absolute;
      top: -1px;
      right: -1px;
      z-index: 1;
      pointer-events: none;
      span {
        display: block;
        width: 60px;
        height: 60px;
        img {width: 100%;height: 100%;}
      }
    }
    .item-pic {
      width: 100%;
      padding-top: 100%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        object-fit: contain;
      }
    }
    .title {
      margin: 6px 0;
    }
    .purchase {
      margin: 10px auto;
      width: 100px;
      height: 30px;
      background: #19a0cf;
      color: #fff;
      cursor: pointer;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.aboutBox {
  width: 100%;
  padding: 1rem;
  // background-color: #232323;
  color: #fff;
  text-align: left;
  background-repeat: no-repeat;
  background-size: 100%;
  .showBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .cont {
      flex: 1;
    }
  }
  .category_name {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 1rem 0;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
  .category_introduce {
    margin-bottom: 10px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
  span {
    margin: 10px 0;
    width: 100px;
    height: 30px;
    background: #19a0cf;
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

// 手机版样式
.mBox {
  position: fixed;
  top: 0;left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  .mTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 5rem;
    img {
      max-height: 2.6rem;
    }
    button {
      width: 2.6rem;
      height: 2.6rem;
      padding: 0;
      margin: 0;
      background-color: #19a0cf;
      border: none;
      outline: none;
      i {
        font-size: 1.8rem;
        color: #fff;
      }
    }
    button:active {
      border: 1px solid #abc7d1;
      background-color: #37a5ca;

    }
  }
  .mMenu {
    width: 100%;
    font-size: 1.3rem;
    background-color: #fff;
    box-shadow: 0 2px 5px #ccc;
    div { font-size: 1.3rem; }
    .mMenuBox {
      font-size: 1.3rem;
      border-top: 1px solid #ccc;
      text-align: left;
      .mTitle {
        display: block;
        padding: 1.2rem;
      }
      .subMenu {
        border-top: 1px solid #ccc;
        padding: 1rem 2rem;
        overflow: hidden;
        // display: none;
        li {
          font-size: 1.2rem;
          padding: 0.6rem 0;

        }
      }
    }
    
  }
}

.mMenuDH-enter-active {
   animation: expand-contract 0.5s ease;
 }
 .mMenuDH-leave-active {
   animation: expand-contract 0.3s ease-out reverse;
 }
 
.subMenuDH-enter-active {
   animation: expand-contract 0.5s ease;
 }
 .subMenuDH-leave-active {
   animation: expand-contract 0.3s ease-out reverse;
 }
 // 收缩展开动画
 @keyframes expand-contract {
    
   0% {
     overflow: hidden;
     opacity: 0;
     max-height: 0;
   }
   25% {
     overflow: hidden;
     opacity: 0.6;
   }
   50% {
     overflow: hidden;
     opacity: 0.8;
   }
   100% {
     max-height: 400px;  // 大于等于下拉菜单的高度
     opacity: 1;
   }
 }







</style>
<style lang="scss">
  .headerBox {
    .el-dialog__wrapper {
      .el-dialog__body {
        padding: 30px 40px;
        h2 {
          font-size: 2.6rem;
          font-weight: 500;
          color: #333;
          margin: 0;
        }
        p {
          font-size: 1.6rem;
        }
        .el-input__inner {
          height: 50px;
          font-size: 1.2rem;
        }
      }
    }
  }
  .searchBox .el-input--suffix .el-input__inner {
    border: none;
    border-radius: 40px;
  }

  @media screen and (max-width: 768px) {
    // .headerBox {
    //   .el-dialog__wrapper {

    //   }
    // }
    .el-dialog {
      width: 90% !important;
    }
    .el-message-box__wrapper {
      z-index: 99999 !important;
    }
  }
</style>