<template>
  <div id="app">
    <HeaderBar :isPC="isPC" :style="{paddingTop: isPC?'61px': '5rem'}"></HeaderBar>
    <router-view :isPC="isPC" :key="key"></router-view>
    <FooterBar :isPC="isPC"></FooterBar>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar';
import FooterBar from '@/components/FooterBar';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      isPC: true
    }
  },
  mounted() {
    
    this.$nextTick(() =>{
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    });
  },  
  components: {
    HeaderBar,
    FooterBar
  },
  computed: { 
    // ...mapState(['isResponse']),
    key() { 
      return this.$route.name !== undefined? this.$route.name + +new Date(): this.$route + +new Date() 
    }
  },
  methods: {
    //屏幕实时响应切换pc or m header
    handleResize() {
      this.screenWidth = window.innerWidth
      if(innerWidth <= 768) {
        this.isPC = false
      }else {
        this.isPC = true
      }
    },
  }
}

/** 禁止IOS手机端页面缩放*/
window.onload = function() {
  document.addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })
}


/**解决电脑端和手机端页面缩放导致的兼容报错*/
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
</script>

<style lang="scss">
:root {
  --theme-color: #179fcf;
  --bg--qianhui: #f7f7f7;
}
* {
  word-wrap: break-word;
}
img {
  // object-fit: contain !important;
}
html,div,body,ul,li{
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
}
body{
  // background: #f5f5f5;
  background: #fff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}

.clearfloat:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0
}

.clearfloat {
    zoom: 1
}

.borderBox {
  border-radius: 2px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  cursor: pointer;
}
.borderBox:hover {
  transition: 0.1s;
  box-shadow: 0px 1px 8px 0px #ccc;
}
.slh-1 {
  width:100%;
  overflow: hidden;    
  text-overflow:ellipsis;    
  white-space: nowrap;
}
.slh-2 {
  width:100%;
  display: -webkit-box;    
  -webkit-box-orient: vertical;    
  -webkit-line-clamp: 2;    
  overflow: hidden;
}
//elementUI 公共样式配置
.el-input__inner:focus {
  border-color: #179fcf !important;
}
.el-input__inner::placeholder, input::placeholder {
  color: #aaa !important;
}

@media screen and (max-width: 1620px) {
  #app .content-auto,
  #app .content-list,
  #app .header-auto
  {
    width: 80%;
  }
  .header-auto,
  .sub-menu-auto,
  .footer-auto
  {
    width: 80% !important;
  }
}
@media screen and (max-width: 768px) {
  #app .content-auto,
  #app .content-list,
  #app .header-auto
  {
    width: 96%;
  }
  .header-auto,
  .sub-menu-auto,
  .footer-auto
  {
    width: 96% !important;
  }
}
</style>
