import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import loginRequest from './utils/LoginRequest'
import webRequest from './utils/WebRequest'
import commonFn from './utils/common'
import getData from './utils/getData'
import logout from './utils/logout'
import MetaInfo from 'vue-meta-info'

Vue.prototype.$loginRequest = loginRequest
Vue.prototype.$webRequest = webRequest
Vue.prototype.$commonFn = commonFn
Vue.prototype.$getData = getData
Vue.prototype.$logout = logout
Vue.prototype.$imgUrl = 'https://api.vdiagtool.com/'//线上
// Vue.prototype.$imgUrl = 'http://vdapi.api-web.top/'//测试
// Vue.prototype.$imgUrl = 'https://rx.api-web.top/'

Vue.config.productionTip = false

Vue.use(ElementUI, {locale})
Vue.use(MetaInfo)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
