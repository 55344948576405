function logout() {
  console.log('logout')
  //清除登录状态
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  this.$store.commit('setIsLogin',false)
  const reUserInfo =  {
    user_account: '',
    user_phone: '',
    user_email: '',
    user_headPic: '',
    user_registertime: '',
    user_shippingAddress: ''
  }
  this.$store.dispatch('asyncUpdateUser', reUserInfo)
  window.location = '/login';
}

export default logout